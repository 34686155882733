/* .fallback-spinner {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.effect-1,
.effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #2962ff;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.effect-1 {
  animation: rotate 1s ease infinite;
}
.effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.effect-3 {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #2962ff;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}
.fallback-logo {
  position: absolute;
  left: calc(50% - 45px);
  top: 40%;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
} */


.fallback-spinner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 98vh;
  width: 100%;
}

.loading-text {
  font-size: 1.5rem;
  color: #2962ff;
  margin: 10px 0;
  text-align: center;
}

.loading {
  position: relative;
  width: 55px;
  height: 55px;
}

.effect-1,
.effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #2962ff;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.effect-1 {
  animation: rotate 1s ease infinite;
}

.effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}

.effect-3 {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #2962ff;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

/* Animation for dots */
@keyframes dotFlashing {
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}

.dot {
  animation: dotFlashing 1s infinite linear;
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}
