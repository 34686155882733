.alignCenter {
  bottom: 0px;
  margin-top: 60px;
  margin-left: 45px;
}

.iconAlign {
  display: flex;
  align-items: flex-end;
}

.link-span {
  color: blue;
  font-size: x-small;
  text-align: right;
  cursor: pointer;
}

.link-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: flex-end;
  justify-content: space-between;
}

/* Add this to your CSS or use a styling solution like styled-components */

.color-tile-container {
  display: flex;
  flex-wrap: wrap;
}

.color-tile {
  width: 150px;
  height: 150px;
  margin: 10px;
  cursor: pointer;
}

.color-tile-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-size: cover;
}

.image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image {
  width: 100%;
  max-width: 100%;
  /* Adjust max-width as needed */
  height: auto;
  border: 2px solid #ccc;
  /* Add a border for better visibility */
  margin-bottom: 10px;
  /* Adjust margin as needed */
}

.thumbnails {
  display: flex;
}

.thumbnail {
  width: 100px;
  height: auto;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  /* Add a border for better visibility */
  transition: border-color 0.3s ease;
  /* Smooth transition for border color change */
}

.thumbnail:hover {
  border-color: #3498db;
  /* Change border color on hover */
}

.product_name {
  box-sizing: border-box;
  margin: 0px;
  font-weight: 700;
  line-height: 1.5;
  /* font-size: 1.125rem; */
  font-family: 'Public Sans', sans-serif;
  /* text-align: center; */
}

.product_price {
  box-sizing: border-box;
  margin: 0px;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.125rem;
  font-family: 'Public Sans', sans-serif;
  text-align: left;
}

/* src/App.css */

/* src/App.css */
.thumbnail-container {
  overflow-x: auto;
}

.thumbnails {
  display: flex;
  gap: 10px;
  padding: 10px;
  /* Optional: Add some padding for better spacing */
}

.thumbnail-wrapper {
  position: relative;
}

.thumbnail-wrapper button {
  position: absolute;
  top: 5px;
  right: 5px;
}

.thumbnail-wrapper.add-more {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #eee;
  border: 2px dashed #ccc;
  width: 100px;
  height: 100px;
}

.thumbnail-wrapper.add-more span {
  font-size: 14px;
  color: #333;
}

/* Edit Page */
.editButton {
  text-align: right;
  padding: 20px;
}

/* Add Product Category */

.variationContainerMain {
  position: relative;
}

.submitButton {}

.marginTop20 {
  margin-top: 20px !important;
}

.marginTop40 {
  margin-top: 40px !important;
}

.marginRight20 {
  margin-right: 20px !important;
}

.addAttribute {}

.imageUploadContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(145, 158, 171, 0.08);
  border: 1px dashed rgba(145, 158, 171, 0.2);
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 15px;
  border-radius: 25px;
  margin-top: 20px;
}

.uploadText {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  opacity: 1;
}

.uploadText.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  background: #00000059;
  opacity: 0;
  z-index: 999;
  border-radius: 15px;
  color: white;
  top: 75px;
  padding: 20px;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.imageUploadContainer .hoverBlur:hover .uploadText.active {
  opacity: 1;
}

.imageUploadContainer .hoverBluredit .uploadText.active:hover {
  opacity: 1;
  z-index: 999;
}

/* .imageUploadContainer .hoverBluredit:hover .main-image {
  filter: blur(8px);
} */

.imageUploadContainer .hoverBlur:hover .main-image {
  filter: blur(8px);
}

.imageUploadContainer .hoverBluredit .hoverBlur:hover .main-image {
  filter: blur(0px);
}

.variationContainer label {
  margin-top: 0px;
}

.hoverBlur {
  display: grid;
  place-items: center;
  width: -webkit-fill-available;
  overflow: hidden;
  border-radius: 15px !important;
}

.hoverBluredit {
  display: grid;
  place-items: center;
  width: -webkit-fill-available;
  overflow: hidden;
  position: relative;
  border-radius: 15px !important;
}

.imageUploadContainer .imageUploadimg {
  width: 120px;
}

.imageUploadContainer input {
  display: none;
}

/* Image */
.imageUploadInner {
  overflow: hidden;
  margin: auto;
  width: -webkit-fill-available;
  position: relative;
}

.imageUploadInner .main-image {
  width: -webkit-fill-available;
  height: 400px;
  object-fit: cover;
  border: 2px solid #ccc;
  margin-bottom: 10px;
  border-radius: 20px;
}

.deletIcon {
  position: absolute;
  top: 44px;
  border-radius: 17px;
  border: 2px solid red;
  right: 0px;
  opacity: 0.5;
  padding: 6px 8px !important;
}

.deletIcon:hover {
  opacity: 1;
}

.imageUploadInner .thumbnails {
  display: flex;
  overflow-x: auto;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.imageUploadInner .thumbnail:hover {
  border-color: #3498db;
  /* Change border color on hover */
}

.imageUploadInner .thumbnail {
  width: 100px;
  object-fit: cover;
  height: 65px;
  margin-right: 0px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: border-color 0.3s ease;
}

/* Track */
.imageUploadInner .thumbnails::-webkit-scrollbar {
  width: 10px;
  /* width of the scrollbar */
  height: 0px;
}

/* Handle */
.imageUploadInner .thumbnails::-webkit-scrollbar-thumb {
  background: #888;
  /* color of the scrollbar handle */
  border-radius: 5px;
  /* radius of the scrollbar handle */
}

/* Handle on hover */
.imageUploadInner .thumbnails::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* color of the scrollbar handle on hover */
}

.thumbnails .css-vj1n65-MuiGrid-root {
  position: relative;
}

.imageUploadInner .closeIcon {
  position: absolute;
  right: 15px;
  background: #0000008c;
  padding: 2px;
  width: 20px;
  top: 5px;
  color: white;
  height: 20px;
  border-radius: 10px;
}

.variationContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
  background-color: rgba(145, 158, 171, 0.08);
  border: 1px dashed rgba(145, 158, 171, 0.2);
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 15px;
  border-radius: 25px;
  margin-top: 20px;
}

/* Table */
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.TableValue {
  width: 100%;
}

.TableValue td {
  padding: 10px;
}

.TableValue td.title {
  background-color: #ec6b27;
  color: white;
}

.TableValue tr:nth-child(odd) {
  background-color: #f8f6ff;
}

.arrow {
  position: absolute;
  bottom: -25px;
  transform: translateY(-50%);
  background-color: transparent;
  color: rgb(0, 0, 0);
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.left-arrow {
  left: 45%;
}

.right-arrow {
  right: 45%;
}

.alignRight {
  text-align: end;
}

.alignCenter {
  text-align: center;
}

.similarProductTitle {
  padding: 6px 0;
  font-size: 115%;
  line-height: 130%;
  font-weight: 600;
  color: #073b7e;
  display: block;
  margin: 0;
  text-align: center;
}

.MuiButtonBase-root {
  opacity: 1 !important;
}

.similarRight.active {
  pointer-events: none;
}

.navSimilarProduct {
  display: flex;
  justify-content: center;
}

.navSimilarProduct .navSimilarProductInner {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 10px;
}

.hide {
  display: none;
}

.navSimilarProduct .navSimilarProductInner p:hover {
  background-color: #eee;
}

.navSimilarProduct .navSimilarProductInner svg:hover {
  background-color: #eee;
}

.navSimilarProduct .navSimilarProductInner p {
  padding: 2px 10px;
  border-radius: 25px;
  color: black;
}

.navSimilarProduct .navSimilarProductInner svg {
  /* padding: 8px 15px; */
  border-radius: 25px;
}

.activeNumber {
  background-color: #ec6b27;
  color: white !important;
}

.simlarWidth {
  width: 25%;
}

.simlarWidth img {
  height: 250px !important;
  border-radius: 15px;
  width: 100%;
  object-fit: cover;
}

.unicID {
  font-size: 14px;
  color: #555;
}

.ProductEditView input {
  border: 5px solid white;
  padding: 0px 10px;
  background: white;
}

.ProductEditView .MuiSelect-select {
  border: 5px solid white;
  padding: 0px 10px;
  background: white;
}

.mouseHover {
  cursor: pointer;
}

.buttonDesign {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 8px;
  background-color: white;
  border-radius: 10px;
}

.buttonDesign div {
  display: flex;
  align-items: center;
}

.group-product {
  display: grid;
}

.MuiAccordionSummary-content {
  padding: 15px;
}

.removeButton {
  display: flex;

  align-content: flex-end;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 720px) {
  .canvas {
    width: 90vw;
    height: 100vh;
  }
}

@media (min-width: 720px) {
  .canvas {
    width: 800px;
    height: 600px;
  }
}

/* Generate List Page */
@media (max-width: 720px) {
  .signature-canvas {
    width: 100%;
  }

  .formDesign .css-17pmaar-MuiCardContent-root {
    padding: 10px !important;
  }
}

.drawingSection {
  overflow: hidden;
  margin: 20px 0px;
  box-shadow: 0px 0px 3px 1px rgb(240, 240, 240);
  border: 0.1px solid;
  border-radius: 10px;
}

.formDesign .css-k0mvf9-MuiTypography-root {
  margin-top: 5px;
}

.tabelpdf th {
  background-color: transparent !important;
}

.removeButtoninner {
  margin-bottom: 0px !important;
}

.Buttonadd {
  margin-top: 20px !important;
  margin-left: 20px !important;
}

.btn {
  margin-right: 20px;
  margin-bottom: 20px;
}

.typography {
  font-size: 15px;
  padding: 20px 0px;
}

.accordian {
  width: 100%;
  color: white;
  background-color: rgb(255, 111, 83);
  padding: 5px;
  border-radius: 10px 10px 0px 0px;
}

.jobs-typography {
  text-align: center;
  padding: 6px;
  font-weight: bold !important;
  font-size: 22px !important;
}

.google-signin-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #d0d7e1;
  color: #000000;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-signout-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #000000;
  border: 1 px solid;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-signin-button:hover {
  background-color: #e3e9f2;
}

.google-signout-button:hover {
  background-color: #efe4e4;
  color: #ffffff;
}

.google-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* .colorIcon {
  margin: 0px;
  position: relative;
  top: 30px;
  left: -15px;
  border-radius: 50%;
} */

.colorList {
  display: flex;
  flex-flow: wrap;
  margin-top: 5px;
  position: absolute;
  background: #f1f1f1;
  padding: 4px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 9999;
}

.dateField {
  display: flex;
  align-items: center;
}

.date-field {
  border: 0px;
  padding: 5px;
  text-align: center;
}

.viewCalendar {
  display: flex;
  padding: 10px;
  background: #39535a;
  justify-content: space-around;
  color: white;
  align-items: center;
}

.preview-active {
  position: relative;
  opacity: 1;
  visibility: visible;
}

.preview-inactive {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.CardBackground {
  background: #ecf2ff;
  border-radius: 10px 10px;
}

.innerList {
  padding: 10px 10px 30px;
  height: calc(100vh - 27vh);
  overflow-y: scroll;
}

.innerList::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.innerList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.innerList::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.innerList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiAccordionSummary-content {
  padding: 15px 0px;
  margin: 0px;
}

.DateandTime {
  position: absolute;
  bottom: 0px;
  font-size: 11px !important;
  right: 0px;
  padding: 2px 5px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #ecf2ff;
}

.leadTimeStatus {
  position: absolute;
  bottom: 0px;
  font-size: 13px !important;
  left: 0px;
  padding: 1px 8px;
  border-top-right-radius: 9px;
  border-bottom-left-radius: 9px;
  background: #ecf2ff;
}

.StatusList {
  position: absolute;
  bottom: 0px;
  font-size: 11px !important;
  left: 0px;
  padding: 2px 5px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #ecf2ff;
}

.listPoductCon {
  margin-top: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.listInericon {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  row-gap: 10px !important;
}

.seperator {
  width: 100%;
  height: 1px;
  background: #a8a2a2;
  margin-top: 15px;
}

.accodian {
  margin: 10px 0;
  border-radius: 10px !important;
}

.textBold {
  font-weight: 600;
  color: #000000;
  line-height: 30px;
}

.buttonList {
  margin-top: 15px !important;
  padding: 3px;
}

.accordian-status {
  margin-top: 20px;
}

.dashboard-date {
  display: flex;
  flex-direction: column;
}

.expiry-table {
  height: 100%;
  overflow-y: auto;
  width: 100% !important;
}

.labelValuePair {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textBold {
  font-weight: bold;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
}

.addField {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-end;
}

footer {
  flex-direction: row !important;
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
}

@media (max-width: 1200px) {
  footer {
    min-height: 20px !important;
    text-align: center;
    display: flex;
    flex-direction: column !important;
  }
}

footer a {
  text-decoration: none;
  color: #000000;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .breadcrubms_button {
    display: none;
  }
}

.locationField {
  width: 100%;
  height: 45px;
  border-radius: 7px;
  border-color: #dfe5ef !important;
}

.productImage {
  column-gap: 15px;
  flex-wrap: nowrap !important;
}

.listTable {
  height: calc(100vh - 40vh);
  overflow: auto;
}

/* width */
.listTable::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.listTable::-webkit-scrollbar-track {
  background: #fbf9f9;
}

/* Handle */
.listTable::-webkit-scrollbar-thumb {
  background: #aba7a7;
}

/* Handle on hover */
.listTable::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiPaper-root .MuiMenu-paper .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded {
  width: 100% !important;
  left: 1200px !important;
}

@media (min-width: 780px) {
  .box-container {
    column-count: 4;
    column-gap: 10px;
    margin-top: 40px;
  }

  .textTypeLabel {
    width: 60% !important;
  }

  .textLabelClick {
    width: 60%;
  }
}

@media (max-width: 780px) {
  .box-container {
    column-gap: 15px;
    margin-top: 40px;
  }

  .box-content {
    margin-left: 20px;
  }

  .textTypeLabel {
    width: 100% !important;
  }

  .textLabelClick {
    width: 100%;
  }
}

.item {
  display: inline-block;
  width: 100%;
}

.box-content {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.item:hover .box-content {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #9c9b9b;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  /* Additional styles */
}

/* Notes */
.textLabelClick {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 0px 16px;
  border-radius: 10px;
  display: flex;
  text-align: center;
  letter-spacing: 0.01428571em;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  min-height: 46px;
  height: 40px;
  overflow: hidden;
}

.topLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NotesContainer {
  margin-top: 20px !important;
}

.textTypeLabel {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
  display: flex;
  text-align: center;
  letter-spacing: 0.01428571em;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  overflow: hidden;
  flex-direction: column;
  z-index: 9999;
  padding: 15px;
  background-color: white;
}

.textTypeLabel .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.textTypeLabel input {
  font-weight: 600;
}

.textTypeLabel textarea {
  width: 100%;
  overflow: hidden;
  padding: 5px 15px;
  border: 0px;
  margin-top: 10px;
  font-size: 0.875rem;
  background: transparent;
}

.textTypeLabel textarea:focus-visible {
  outline: white !important;
}

.customeStyleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
}

.customeStyleBox button {
  margin: 0px;
  background: white;
  color: black;
  font-weight: 600;
}

.react-datepicker__input-container input {
  border-radius: 25px;
  background-color: #ebebeb;
  font-weight: 400;
  font-size: 12px;
}

.customeStyleBox button:hover {
  background-color: #5f636829;
}

.buttonColor {
  border: 1px solid black !important;
}

.addNotes {
  display: flex;
  column-gap: 15px;
  align-items: center;
}

.removeIcon {
  color: #ff0000;
}

.notesFooter {
  display: flex;
  justify-content: space-between;
}

.line {
  border-top: 1px black;
}

.notedUser {
  /* display: grid; */
  font-size: 10px;
}

.invoice-table {
  width: 100%;
  margin: 25px 0px 5px;
}

.invoice-table td {
  padding: 8px;
}

.table-title {
  text-align: right;
  font-weight: 500;
  font-size: 0.875rem;
}

.table-value {
  text-align: center;
  font-size: 16px;
}

.accodian-name {
  margin-left: 20px;
  margin-top: 10px;
}

.react-datepicker__input-container input {
  border-radius: 5px !important;
  font-size: 16px !important;
  background-color: #ffffff !important;
  border: none;
  font-size: 0.875rem !important;
  color: #2a3547 !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.invoice-typography {
  margin-top: 10px !important;
  margin-left: 10% !important;
}

.job-property p {
  padding: 5px;
  font-weight: 600;
}

.job-property span {
  font-weight: 100 !important;
  padding-left: 10px;
}

.accordion-summary div {
  margin: 0 !important;
  font-weight: 900;
  font-size: 17px;
  /* color: white; */
}

.select {
  width: 100%;
}

.btn {
  cursor: pointer;
}

* {
  /* font-size: 14px !important; */
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.nav-hover:hover {
  background: #01204e;
}

.nav-hover {
  padding: 3px 10px;
  border-radius: 10px;
  background: #01204e80;
  color: white !important;
}

.enableNotification {
  display: grid;
  align-items: end;
  justify-content: start;
}

/* table css */
.alternate-row-color thead tr {
  background-color: #ecf2ff !important;
}

.alternate-row-color tbody tr:nth-child(even) {
  background-color: #f2f2f2 !important;
  /* Light grey color for odd rows */
}

.alternate-row-color tbody tr:nth-child(odd) {
  background-color: #ffffff !important;
}

@keyframes loadingAnimation {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.loadingBackground {
  background-size: 200px 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0px,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 100%);
  animation: loadingAnimation 1.5s infinite;
}

.alternate-row-color thead tr {
  background-color: #8da8d3 !important;
}

.alternate-row-color tbody tr:nth-child(even) {
  background-color: #ecf2ff !important;
  /* Light grey color for odd rows */
}

.alternate-row-color tbody tr:nth-child(odd) {
  background-color: #ffffff !important;
}

body.dark-mode .alternate-row-color tbody tr:nth-child(odd) {
  background-color: #495057 !important;
  color: #fff !important;
}

body.dark-mode .alternate-row-color tbody tr:nth-child(even) {
  background-color: #343a40 !important;
}

.alternate-row-color tbody tr td {
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important; */
  font-size: 14px !important;
  font-weight: 400 !important;
}

.alternate-row-color th {
  font-family: sans-serif !important;
  font-size: 16px !important;
}

/* styles.css */

/* styles.css */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  overflow-x: auto;
  /* This allows horizontal scrolling on smaller screens */
}

.styled-table th,
.styled-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.form-checkbox {
  cursor: pointer;
  transform: scale(1.2);
}

.inline-flex {
  display: inline-flex;
  align-items: center;
}

.table-container {
  overflow-x: auto;
  /* This allows horizontal scrolling for the table container */
}

@media (max-width: 600px) {

  .styled-table th,
  .styled-table td {
    padding: 8px;
  }

  .styled-table th {
    font-size: 12px;
  }

  .styled-table td {
    font-size: 10px;
  }

  .inline-flex {
    display: block;
  }
}

/* .styled-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
}

.styled-table th,
.styled-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.form-checkbox {
  cursor: pointer;
  transform: scale(1.2);
}

.inline-flex {
  display: inline-flex;
  align-items: center;
} */

.css-13afn1t-MuiButtonBase-root-MuiTableSortLabel-root .MuiTableSortLabel-icon {
  color: #fff !important;
}



.css-gb3i19-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  /* color: #2A3547; */
  opacity: 1 !important;
}

.css-m8ctoa-MuiInputBase-root-MuiInput-root {
  color: #fff !important;
}

li.MuiBreadcrumbs-separator.css-1wuw8dw-MuiBreadcrumbs-separator {
  color: #092754 !important;
}

.alertMessage .alertTitle {
  font-size: '14px' !important;
  text-align: center !important;
}

td {
  font-size: 14px !important;
}

/* -> dashboard date range styles taken from ramcides (not used) */

.datePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datePopupInner {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.applyButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #3d91ff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.applyButton:hover {
  background-color: #357ae8;
}

/* Filter Title */
.filterTitle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.filterTitle .DateTitle {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

/* Typography classes */
.subTitleMain {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #000;
}

.alignTextRight {
  text-align: right !important;
}

/* Top Filter */
.topFilter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.topFilter.sticky-header {
  position: fixed;
  top: 11vh;
  width: 93%;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* Dash Box Container */
.dash-boxContainer {
  padding: 10px;
  background-color: #fff;
  margin: 0 !important;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dash-boxContainer .innerFIlterContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
}

.dash-boxContainer .innerFIlterContent .enptyText {
  margin-left: 20px;
  color: #ff0000;
  font-size: 14px;
}

.dash-boxContainer .innerFIlterContent .filterButton {
  background-color: #d3d3d3;
  margin-bottom: 10px !important;
  width: auto;
  max-width: fit-content;
  margin: 0 10px !important;
  padding: 3px 15px;
  border-radius: 15px;
}

.dash-boxContainer .innerFIlterContent .filterButton button {
  width: max-content;
  padding: 0;
  font-size: 18px;
}

.dash-boxContainer .innerFIlterContent .filterButton h6 {
  font-size: 14px;
  color: black;
  font-weight: 600;
}

.dash-boxContainer .navButtonMain {
  padding: 3px;
  border-radius: 10px;
}

.critical_compliance {
  display: grid;
  justify-content: space-around;
  align-items: end;
  justify-items: stretch;
}

.apexcharts-legend {
  align-items: start !important;
  margin-left: 60px !important;
}

.MuiInputBase-fullWidth {
  border-bottom: 1px solid #fff;
}

.MuiTableCell-root .MuiInputBase-fullWidth .MuiInputBase-input {
  color: white;
}

.table-color-black .MuiInputBase-fullWidth .MuiInputBase-input {
  color: #000;
}

.custom-tooltip {
  max-width: 200px;
  /* Adjust this value as needed */
  white-space: normal;
  /* Allow text to wrap */
  overflow-wrap: break-word;
  /* Break long words */
}

.css-1u9jhwc-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 4.5px !important;
}